<template>
  <div class="PDFScreen">
    <div v-if="isLoading" class="loading">
      <div class="loader"></div>
    </div>
    <div>
      <vue-pdf-embed
          ref="pdfRef"
          :source="src"
          @rendered="loadDocument"
      />
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  name: "PDFScreen",
  components: {
    VuePdfEmbed,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    loadDocument(args) {
      this.isLoading = false;
    },
    resizeDescription() {
      const headerGroup = document.querySelector("#HeaderGroup--internal");
      const fadedDescription = document.querySelector(".PDFScreen");

      fadedDescription.style.height = 0;

      const headerComponentsHeight = headerGroup.clientHeight;

      console.log(headerComponentsHeight)

      const app = document.querySelector("#app");
      const appPadding =
          parseFloat(window.getComputedStyle(app).paddingBottom) +
          parseFloat(window.getComputedStyle(app).paddingTop);
      console.log(appPadding)
      const componentMargin = parseFloat(
          window.getComputedStyle(document.querySelector(".component"))
              .marginBottom
      );
      console.log(componentMargin)
      const footerHeight = document.querySelector(
          "#FooterGroup--internal"
      ).clientHeight;
      console.log(footerHeight)

      fadedDescription.style.height = `calc(100svh - ${
          headerComponentsHeight +
          appPadding +
          componentMargin +
          footerHeight
      }px)`;
    },
  },
  mounted() {
    this.resizeDescription();
    window.addEventListener("resize", this.resizeDescription);
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

.PDFScreen {
  overflow-y: scroll;
}

.hidden {
  display: none;
}
.loading {
  position: fixed;
  top: 0; right: 0;
  bottom: 0; left: 0;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border-left: .8em solid $primary;
  animation: spin 1.1s infinite linear;
}
.loader, .loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>