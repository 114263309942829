<script>
import Armory from "armory-sdk/src/Armory";
import PDFScreen from "@/components/PDFScreen.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import BigTextArea from "@/components/BigTextArea.vue";
import InputWithDescription from "@/components/InputWithDescription.vue";
import VerificationCode from "@/components/VerificationCode.vue";
import FadedDescription from "@/components/FadedDescription.vue";
import PendingParentRegistrations from "@/components/PendingParentRegistrations.vue";
import ParentRegistrations from "@/components/ParentRegistrations";
import MaterialsDisplay from "@/components/MaterialsDisplay.vue";
import LessonDisplay from "@/components/LessonDisplay.vue";
import RadioSelectFixed from "@/components/RadioSelectFixed";

export default {
  name: "App",
  extends: Armory,
  methods: {
    getCustomTemplates() {
      return {};
    },
    getCustomComponents() {
      return {
        PDFScreen,
        ButtonGroup,
        BigTextArea,
        InputWithDescription,
        MaterialsDisplay,
        LessonDisplay,
        RadioSelectFixed,
        VerificationCode,
        FadedDescription,
        PendingParentRegistrations,
        ParentRegistrations
      };
    },
    // eslint-disable-next-line no-unused-vars
    preReceivedSpecificationTrigger(receivedData) {
    },
    postReceivedSpecificationTrigger() {
    }
  }
};
</script>
