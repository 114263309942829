<template>
  <div class="LessonDisplay">
    <section>
      <b-collapse v-if="metadata.aktivnosti" :open="openCollapse === 'aktivnosti'"aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Aktivnosti"
              outlined
              aria-controls="contentIdForA11y1"
              icon-right="angle-down"/>
        </template>
        <div class="content">
          <div v-for="aktivnost in processedMetadata.aktivnosti" :key="aktivnost.id_aktivnosti">
             <p class="resurs">
                   <span v-html="aktivnost.aktivnost"></span>
                <a v-if="aktivnost.url" :href="aktivnost.url" target="_blank">
                  <font-awesome-icon icon="link" /></a>
              <a class="poveznica" :href="aktivnost.url" target="_blank" style="margin-left: 5px">{{ aktivnost.naziv_linka }}</a>
            </p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.vrednovanje" :open="openCollapse === 'vrednovanje'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Vrednovanje"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="vred in metadata.vrednovanje" :key="vred.vrednovanje">
            <p class="resurs">{{ vred.vrednovanje }}
              <a class="poveznica" :href="vred.url" target="_blank">{{ vred.naziv_linka }}</a>
            </p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.ishodi" :open="openCollapse === 'ishodi'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Ishodi"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="ishod in metadata.ishodi" :key="ishod.sifra">
            <p class="resurs"><b>{{ ishod.sifra }}</b> {{ ishod.razrada }}</p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.medjupredmetne_teme" :open="openCollapse === 'medjupredmetne_teme'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Međupredmetne teme"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="mpt in metadata.medjupredmetne_teme" :key="mpt.sifra">
            <p class="resurs"><b>{{ mpt.sifra }}</b> {{ mpt.razrada }}</p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.korisni_linkovi" :open="openCollapse === 'korisni_linkovi'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Korisni linkovi"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="link in metadata.korisni_linkovi" :key="link.naziv_linka">
            <p class="resurs">
              <a :href="link.url" target="_blank">
                <font-awesome-icon icon="link" /></a>
              <a class="poveznica" :href="link.url" target="_blank" style="margin-left: 5px">{{ link.naziv_linka }}</a>
            </p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.opisne_ocjene" :open="openCollapse === 'opisne_ocjene'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Opisne ocjene"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="ocjena in metadata.opisne_ocjene" :key="ocjena.ocjena">
            <p class="resurs"><b>Ocjena {{ ocjena.ocjena }}:</b> {{ ocjena.opis }}</p>
          </div>
        </div>
      </b-collapse>

      <b-collapse v-if="metadata.dnevnik_rada" :open="openCollapse === 'dnevnik_rada'" aria-id="contentIdForA11y1">
        <template #trigger="props">
          <b-button
              class="naziv-resursa"
              label="Dnevnik rada"
              type="is-primary is-light"
              aria-controls="contentIdForA11y1"
              :aria-expanded="props.open"
              icon-right="angle-down"/>

        </template>
        <div class="content">
          <div v-for="dnevnik in metadata.dnevnik_rada" :key="dnevnik.zapis">
            <p class="resurs">{{ dnevnik.zapis }}</p>
          </div>
        </div>
      </b-collapse>
    </section>
  </div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "LessonDisplay",
  props: {
    text: String,
    metadata: Object,
    open: String, // The name of the collapse element that should be open by default
    highlight: String // The text to highlight
  },
  data() {
    return {
      openCollapse: this.open
    };
  },
  methods: {
    toggleCollapse(collapseName) {
      if (this.openCollapse === collapseName) {
        this.openCollapse = null; // Close the collapse if it's already open
      } else {
        this.openCollapse = collapseName; // Open the clicked collapse
      }
    }
    ,
    mounted() {
      console.log(this.metadata);
      console.log("---------------");
      console.log(this.metadata.aktivnosti);

    },
    loaded() {
      console.log("loaded");
      console.log(this.metadata);
      console.log("---------------");
      console.log(this.metadata.aktivnosti);
    }
  },
  computed: {
  processedMetadata() {
    const highlight = this.highlight;
    // Clone the metadata object to avoid mutating the original prop
    let processed = JSON.parse(JSON.stringify(this.metadata));

    // Define a function to recursively search and highlight text
    function highlightText(obj) {
      for (let key in obj) {
        if (typeof obj[key] === 'string') {
          // Replace the highlight text with a span containing the highlight class
          obj[key] = obj[key].replace(new RegExp(`(${highlight})`, 'gi'), '<span class="highlight" style="background-color: #FFF176;">$1</span>');
        } else if (typeof obj[key] === 'object') {
          highlightText(obj[key]); // Recurse into nested objects
        }
      }
    }

    highlightText(processed); // Apply the highlighting
    console.log(processed);
    return processed;
  }
},

};
</script>
<style lang="scss" scoped>

.resurs {
  background-color: #f4f4f4 !important;
  outline: auto;
  outline-color: #f5f5f5;
  color: black;
  padding: 10px; /* Add padding for better readability */
  margin: 3px 0; /* Add margin for better readability */
  font-size: medium;


}

.poveznica {
  color: #094e91 !important;
  text-decoration: underline !important;
}

.naziv-resursa {
  width: 100%;
  background-color: #fce6e8ff !important;
  color: black !important;
  font-weight: normal;
  padding: 10px;
  text-align: left;
  border-radius: 5px !important;
  margin-block: 7px;
  box-shadow: none !important;
}
.highlight {
  background-color: greenyellow; /* Your preferred highlight color */
  padding: 0.2em 0.4em; /* Increase padding to make the highlight bigger */
  border-radius: 0.5em; /* Increase border-radius to make the corners rounder */
  box-decoration-break: clone; /* Ensures the background extends across line breaks */
  -webkit-box-decoration-break: clone; /* For WebKit browsers */
}

</style>
