<template>
  <div :class="this.classes()">
    <div
        v-for="(component, index) in this.childComponents"
        :key="index + '-parent'"
        class="component content"
    >
      <component
          :is="name"
          v-bind="spec"
          :key="index + '-' + name"
          v-for="(spec, name, index) in component"
      >
      </component>
    </div>
  </div>
</template>

<script>
import SubmitButton from "armory-sdk/src/components/SubmitButton.vue";

export default {
  name: "ButtonGroup",
  components: {
    SubmitButton,
  },
  data: () => {
    return { id: Math.random().toString(36).slice(2, 9) };
  },
  methods: {
    classes() {
      if (this.customClasses && this.customClasses.length > 0)
        return "ButtonGroup component content " + this.customClasses.join(" ");
      return "ButtonGroup component content";
    },
  },
  props: {
    childComponents: {
      type: Array,
      required: true,
    },
    customClasses: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

::v-deep .SubmitButton {
  margin: 0 0 0.2rem 0 !important;

  > button {
    display: flex;
    justify-content: space-between;
    background-color: white !important;
    color: black !important;
    border-radius: 3px !important;
    border: unset !important;
    font-weight: normal !important;
    box-shadow: none;
  }

  > button::after {
    content: ">";
    padding-left: 0.5rem;
  }
}
</style>