Copy code
<template>
  <div>
    <div>
      <div class="text-input">
        <b-input v-model="inputPrompt" class="query"></b-input>
        <b-button class="submit-button" :disabled="inputPrompt === ''" @click="submitSearch" rounded>Traži</b-button>
      </div>
      <div class="filters">
        <b-field :label="filter.name" v-for="filter in filters" label-position="on-border">
          <b-select :ref="filter.value" class="filter-select is-small"
                    :value="activeFilters[filter.value]" :clearable="true" :key="filter.value"
                    :placeholder="filter.name" v-model="activeFilters[filter.value]" rounded>
            <!--                  @input="updateSelectWidth(filter.name)" >-->
            <option :value="undefined">-</option>
            <option v-for="option in filter.options" :value="option.value" :key="option.value"
                    :selected="activeFilters[filter?.value] === option.value">
              {{ option.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Sat" label-position="on-border" custom-class="number-input">
          <b-numberinput v-model="activeFilters.sat" :min="1" :max="999" :step="1" :controls="false"
                         :value="activeFilters.sat" key="sat" placeholder="Sat"
                         size="is-small" class="filter-number" rounded>
          </b-numberinput>
        </b-field>
      </div>
    </div>
    <div class="materials-container">
      <div v-for="material in materials" :key="material.sifra" class="single-material" @click="toggleExpand(material)">
        <div :class="{'material-header': true, 'expanded': material.isExpanded}">
          <div>
            <h3 v-if="material.lekcija">{{ snakeToTitleCase(material.tip_resursa) }} | {{ material.lekcija }}</h3>
            <p v-if="material.razred && material.sat && material.predmet">{{ material.predmet }} | Razred:
              {{ material.razred }} | Sat: {{ material.sat }}</p>
          </div>
          <div class="expand-icon">
            <font-awesome-icon :icon="material.isExpanded ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']"/>
          </div>
        </div>
        <div v-if="material.isExpanded" class="material-block">
          <p v-if="material.sifra">Šifra: {{ material.sifra }}</p>
          <p v-if="material.url && material.naziv_linka">Poveznica: <a :href="material.url"
                                                                       target="_blank">{{ material.naziv_linka }}</a>
          </p>
          <p v-if="material.razrada">Razrada: {{ material.razrada }}</p>
          <p v-if="material.aktivnost">Aktivnost: {{ material.aktivnost }}</p>
          <p v-if="material.vrednovanje">Vrednovanje: {{ material.vrednovanje }}</p>
          <p v-if="material.ocjena">Ocjena: {{ material.ocjena }}
            <span v-if="material.opis">Opis: {{ material.opis }}</span>
          </p>
          <p v-if="material.zapis">Zapis: {{ material.zapis }}</p>
          <p v-if="material.zadnji_bodovi_ocjena">Zadnji bodovi i ocjena: {{ material.zadnji_bodovi_ocjena }}</p>
          <p v-if="material.grupa">Grupa: {{ material.grupa }}</p>
          <p v-if="material.bodovi_zadataka">Bodovi zadataka: {{ material.bodovi_zadataka }}</p>
          <p v-if="material.sifra_ishoda">Šifra ishoda: {{ material.sifra_ishoda }}</p>
          <p v-if="material.broj_zadatka">Broj zadatka: {{ material.broj_zadatka }}</p>
          <p v-if="material.zadnji_bod_razine">Zadnji bod razine: {{ material.zadnji_bod_razine }}</p>
          <p v-if="material.ishod_razine">Ishod razine: {{ material.ishod_razine }}</p>

        </div>
      </div>
      <h3 class="not-found-title" v-if="materials.length === 0">Nisu nađeni materijali za navedeni kriterij</h3>
    </div>
  </div>
</template>


<script>
export default {
  name: "MaterialsDisplay",
  props: {
    inputId: String,
    materials: Array,
    filters: Array,
    filterValues: Object,
    prompt: String,
  },
  data() {
    return {
      inputPrompt: this.prompt || "",
      activeFilters: this.filterValues || {},
    };
  },
  methods: {
    toggleExpand(material) {
      material.isExpanded = !material.isExpanded;
    },
    submitSearch() {

      let payload = {
        type: "template_submit",
        screenId: this.$store.state.activeScreenId,
        data: {
          prompt: this.inputPrompt,
          filters: Object.fromEntries(Object.entries(this.activeFilters).filter(([_, v]) => v != null)),
        },
        buttonId: this.inputId,
      };
      console.log(payload);

      this.$store.commit("submitResponse", {
        payload: JSON.stringify(payload),
        socket: this.$socket,
      });
    },

    snakeToTitleCase(str) {
      return str.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },

    // updateSelectWidth(filterName) {
    //   console.log("Siuu", filterName, this.$refs[filterName])
    //   this.$nextTick(() => {
    //     this.updateWidth(this.$refs[filterName]);
    //     // Add more refs for additional selects as needed
    //   });
    // },
    // // Update the width of a specific b-select element
    // updateWidth(selectRef) {
    //   console.log(selectRef)
    //   const selectedOption = selectRef.$el.querySelector('option:checked');
    //   const textWidth = this.getTextWidth(selectedOption.text);
    //   selectRef.$el.style.width = textWidth + 'px';
    // },
    // // Measure the width of the text
    // getTextWidth(text) {
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');
    //   context.font = '14px Arial'; // Adjust the font size and style as needed
    //   return context.measureText(text).width;
    // },
  },
};
</script>

<style lang="scss" scoped>

@use "~@/assets/css/skin" as skin;

.not-found-title {
  padding: 1em;
}

.submit-button:disabled {
  background-color: skin.$primary-light;
}

.submit-button:not(:disabled) {
  height: 2.5em;
  padding: 0 1.5em;
  box-shadow: none;
  background-color: skin.$primary;
  color: white;
}

.query {
  flex-grow: 1;
}

.filters {
  display: flex;
  gap: 0.8em;
  margin-bottom: 1em !important;
  max-width: 100%;
  //height: 1.5em;
  flex-wrap: wrap;
  //overflow-x: auto; /* Enable horizontal scrolling */
}

.text-input {
  display: flex;
  gap: 1em;
  padding-bottom: 1em;
  justify-content: space-between;
}

:deep(.filter-select) {
  font-size: small;
  min-width: 5.8em !important;
}

:deep(.filter-number) {
  max-width: 4em !important;
}

.expand-icon {
  font-size: 18px;
  color: #4A4A4A;
}

:deep(.number-input) {
  margin-left: 0 !important;
}

.materials-container {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.single-material {
  width: 100%;
  margin-bottom: 1em;
}

.material-header {
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 15px 10px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8; /* Change the background color as needed */

  h3 {
    color: #da2128;
    font-size: 16px;
    margin: 0 0 4px 0;
  }

  p {
    margin: 0 0 4px 0;
    font-size: 14px;
    color: #4A4A4A;
  }
}

.expanded {
  border-radius: 10px 10px 0 0; /* Change the border-radius for expanded state */
  border-bottom: unset;
  padding-bottom: 0;
}

.material-block {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 0 10px 15px 15px;
  border-radius: 0 0 10px 10px;
  text-align: start;
  border-top: unset;
  background-color: #f8f8f8; /* Change the background color as needed */

  p {
    margin-bottom: 4px;
    font-size: 14px;
  }
}

.block-title {
  font-size: 14px;
  margin-bottom: 8px;
}

a {
  color: #da2128;
  text-decoration: none;
}
</style>
