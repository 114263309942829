<template>
  <div class="BigTextArea">
    <div class="field">
      <label class="label" v-if="label">{{ label }}</label>
      <div class="control">
        <textarea
          v-model="inputValue"
          :placeholder="placeholder"
          class="textarea"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import BaseValue from "armory-sdk/src/components/base/BaseValue.vue";

export default {
  name: "BigTextArea",
  extends: BaseValue,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
.textarea {
  height: 15rem;
}
</style>
