import { render, staticRenderFns } from "./InputWithDescription.vue?vue&type=template&id=f8da84da&scoped=true"
import script from "./InputWithDescription.vue?vue&type=script&lang=js"
export * from "./InputWithDescription.vue?vue&type=script&lang=js"
import style0 from "./InputWithDescription.vue?vue&type=style&index=0&id=f8da84da&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8da84da",
  null
  
)

export default component.exports