<template>
  <div>
    <h5>Potvrđene prijave za {{ grade }}:</h5>
    <div class="registrations-container"
         @scroll="handleScroll"
         :class="{'no-mask': isAtBottom}">
      <div class="registration"
           v-for="(registration, index) in registrations"
           :key="index">
        <div class="parent-data">
          <p>{{ index + 1 }}. {{ registration.parentName }}</p>
          <p>{{ registration.studentName }}, +{{ registration.phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "ParentRegistrations",
  props: {
    inputId: String,
    registrations: Array,
    grade: String
  },
  data() {
    return {
      isAtBottom: false,
    };
  },
  mounted() {
    this.resizeDescription();
    window.addEventListener("resize", this.resizeDescription);
    this.handleScroll();

  },

  methods: {
    handleScroll() {
      const container = document.querySelector(".registrations-container");
      const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1; // +1 for rounding issues
      this.isAtBottom = isAtBottom;
    },
    resizeDescription() {
      const headerGroup = document.querySelector("#HeaderGroup--internal");
      const registrations = document.querySelector(".registrations-container");

      registrations.style.height = 0;

      const headerComponentsHeight = headerGroup.clientHeight;

      const app = document.querySelector("#app");
      const appPadding =
          parseFloat(window.getComputedStyle(app).paddingBottom) +
          parseFloat(window.getComputedStyle(app).paddingTop);

      const componentMargin = parseFloat(
          window.getComputedStyle(document.querySelector(".component"))
              .marginBottom
      );

      const footer = document.querySelector("#FooterGroup--internal");
      const footerHeight = footer ? footer.clientHeight : 0;

      const legalElement = document.querySelector(".legal");
      const legalHeight = legalElement
          ? legalElement.clientHeight +
          parseFloat(window.getComputedStyle(legalElement).marginTop)
          : 0;

      registrations.style.height = `calc(100svh - ${
          headerComponentsHeight +
          appPadding +
          componentMargin +
          footerHeight +
          legalHeight
      }px)`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

.registrations-container {
  overflow-y: auto; /* Enables vertical scrolling */
  padding-right: 0.3rem; /* Matches the padding inside the registration boxes */

  /* Optional: Adds a blurred effect to the bottom of the container */
  mask-image: linear-gradient(to bottom, black calc(100% - 100px), transparent 110%);
}

.no-mask {
  mask-image: none; /* Removes the mask */
}

.registration {
  background-color: #f5f5f5; /* Light gray background */
  padding: 0.3rem; /* Adds space inside the box */
  margin-bottom: 0.5rem; /* Adds space between registration boxes */
  border: 1px solid #e0e0e0; /* Light gray border for better division */
  border-radius: 8px; /* Optional: Adds rounded corners to the boxes */
  display: flex; /* Enables Flexbox */
  justify-content: space-between; /* Pushes children to opposite ends */
  align-items: center; /* Aligns children vertically in the center */
}

.parent-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.parent-data p {
  font-size: small;
  margin-bottom: 0 !important; /* Reduced bottom margin to bring text elements closer */
}

.parent-data p:first-of-type {
  font-weight: bold; /* Makes the font bold */
  font-size: medium; /* Larger font size for the first <p> element */
}
</style>
